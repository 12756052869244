<template>
  <div class="officialwebsite">
<div class="home-title-box">

</div>
<div class="lindo-corect-box">
<div class="container">
  <div class="h_iframe">
    <iframe src="https://www.lindo.com.cn/distTwo/index.html" frameborder="0" allowfullscreen width="700" height="700" scrolling="no"></iframe>
  </div>
</div>
</div>


  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.officialwebsite{
width: 100vw;
min-height: 100vh;
background-color: rgb(237, 237, 237);

display: inline-block;

}
.home-title-box {
  width: 100vw;
  height: 4.5vw;
  top: 0;
  bottom: auto;
  background-color: #EDEDEDB3;
  border-color: #ffffff;
  border-bottom-style: solid;
    border-bottom-width: 1px;
    backdrop-filter: saturate(180%) blur(10px);
    position: fixed;
    display: block;
    z-index: 10;
}
.lindo-corect-box{
min-height: 100vh;
/* margin-bottom: 5%; */
background-color: rgb(237, 237, 237);
font-size: 0;
    position: relative;
    pointer-events: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.container{
  left: calc( 3% + (100% - 6%) / 11 * 2 + 0.90909090909091%);
  width: calc( (100% - 6%) / 11 * 7 - 1.8181818181818%);
  position: absolute !important;
    margin-left: 0 !important;
    z-index: 1;
    pointer-events: auto;
    display: inline-block;
}
.h_iframe{
  position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    user-select: none;
}
iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
</style>